import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import InputLabel from "../../components/Inputs/InputLabel";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api, apiUrl } from "../../services/api";
import "./login.css";

export default function LoginCard() {
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [openLoad, setOpenLoad] = useState(false);
    const navigate = useNavigate();

    async function handleLogin() {
        setOpenLoad(true);
        try {
            const response = await api.post(`/login`, { user, pass });
            if(response && response.status === 200 && response.data){
                if(response.data.id_usuario){
                    sessionStorage.setItem("infoLogin", JSON.stringify(response.data));
                    return navigate("/");
                }else if(response.data.error){
                    AlertInfo({icon: 'error', title: response.data.message});
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({icon: 'error', title: "Falha na conexão"});
        }
        setOpenLoad(false);
    }

    return (
        <Grid container spacing={1} className="loginCardContainer">
            <SimpleBackdrop open={openLoad} />

            <Grid item xs={12} container justifyContent="center">
                <img src={`${apiUrl}/img/logo-sysprojud`} height={160} alt="Logo SysProJud"></img>
            </Grid>
            <Grid item xs={12}>
                <InputLabel
                    id="user"
                    label="Email"
                    placeholder="user@email.com"
                    value={user}
                    onChange={e => setUser(e.target.value)}
                    next="pass"
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel
                    id="pass"
                    label="Senha"
                    placeholder="********"
                    value={pass}
                    onChange={e => setPass(e.target.value)}
                    type="password"
                    onKeyUp={e => {
                        if(e.key === 'Enter') handleLogin();
                    }}
                />
            </Grid>
            <Grid item xs={12} container justifyContent="end">
                <Button>Esqueci a senha</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={handleLogin}>Entrar</Button>
            </Grid>
        </Grid>
    );
}