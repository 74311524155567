import { Fab, Grid } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Modal from "../../components/Modal/Modal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatDate } from "../../utilities/date";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import InputLabel from "../../components/Inputs/InputLabel";
import { ClienteType } from "../../types/ClienteType";
import { removeMask } from "../../utilities/displayValues";

type ListaClientesProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setInfoCliente?: Dispatch<SetStateAction<ClienteType | null>>
}

export default function ListaClientes({ open, setOpen, setInfoCliente }: ListaClientesProps) {
    const [openLoad, setOpenLoad] = useState(false);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState("");
    const columns = [
        {
            id: 'id_cliente',
            numeric: false,
            label: 'COD.',
        },
        {
            id: 'nome_cliente',
            numeric: false,
            label: 'NOME',
            minWidth: 500
        },
        {
            id: 'data_nascimento_cliente',
            numeric: false,
            label: 'NASCIMENTO',
            format: (dt: any) => formatDate(dt),
        },
        {
            id: 'cpf_cliente',
            numeric: false,
            label: 'CPF',
            minWidth: 150
        },
        {
            id: 'rg_cliente',
            numeric: false,
            label: 'RG',
        },
        {
            id: 'telefone_cliente',
            numeric: false,
            label: 'TELEFONE',
            minWidth: 150
        },
        {
            id: 'cidade_cliente',
            numeric: false,
            label: 'CIDADE',
            minWidth: 300
        },
    ];

    async function loadClientes() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/clientes`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message })
                } else {
                    if (response.data.length > 0) {
                        setRows(response.data);
                        setAllRows(response.data);
                    } else {
                        AlertInfo({ icon: 'info', title: "Nenhum cliente localizado" });
                    }
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "Falha na conexão" });
        }
        setOpenLoad(false);
    }

    function handleClick(cli: ClienteType) {
        if(setInfoCliente){
            setInfoCliente(cli);
            setOpen(false);
        }
    }

    function filterClient() {
        if(search.length > 0){
            const b = search.toUpperCase();
            const fil = allRows.filter((c: ClienteType) => c.nome_cliente.includes(b) || removeMask(c.cpf_cliente).includes(b) || removeMask(c.rg_cliente).includes(b));
            setRows(fil);
        }else{
            setRows(allRows);
        }
    }

    useEffect(() => {
        loadClientes();
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Buscar Cliente"
            maxWidth="md"
            zIndex={2222}
        // actionClose={resetStates}
        >
            <Grid container spacing={1}>
                <SimpleBackdrop open={openLoad} />
                <Grid item xs={false} sm={7}></Grid>
                <Grid item xs={12} sm={5}>
                    <div style={{display:'flex'}}>
                    <InputLabel
                        value={search}
                        setState={setSearch}
                        placeholder="Buscar"
                        isUpper
                        onKeyUp={e => {
                            if(e.key === 'Enter') filterClient();
                        }}
                    />
                    <Fab size="medium" color="primary" aria-label="search" style={{marginLeft: '-44px'}} onClick={filterClient}>
                        <SearchIcon />
                    </Fab>
                    </div>
                </Grid>
                {rows.length > 0 && (
                    <Grid item xs={12}>
                        <TableDataSimple
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                        />
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
}