import axios from "axios";

export const apiUrl = `https://api.sysprojud.com.br`;
// export const apiUrl = `http://localhost:1234`;

export const api = axios.create({
    baseURL: apiUrl,
});

api.interceptors.request.use(config => {
    const infoLogin = JSON.parse(String(sessionStorage.getItem("infoLogin")));
    config.headers.token = (infoLogin && infoLogin.token) ? infoLogin.token : '';
    return config;
});