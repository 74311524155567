import { useState } from 'react';
import { FaFileImage, FaXmark, FaTrash } from "react-icons/fa6";
import Dropzone from 'react-dropzone';
import Button from "@mui/material/Button";
import SimpleBackdrop from '../Loading/SimpleBackdrop';
import { api } from '../../services/api';
import { AlertInfo } from '../Alerts/Alert';

type UploadMsgProps = {
    children?: JSX.Element[] | JSX.Element
    color?: string
}

function UploadMsg({ children, color }: UploadMsgProps) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '15px 0',
                backgroundColor: color ? color : "#fff"
            }}
        >
            {children}
        </div>
    );
}

type FileUploadProp = {
    file: File,
    uploaded: boolean,
    preview: string,
    url: string | null,
}

type AditionalData = {
    name: string,
    value: any
}

type DropZoneProps = {
    route: string,
    accept: string[],
    currentFile?: string | null,
    handleDelete?: () => void,
    aditional?: AditionalData[],
}

export default function DropZoneFile({ route, accept, currentFile, handleDelete, aditional }: DropZoneProps) {
    const [openLoad, setOpenLoad] = useState(false);
    const [fileUpload, setFileUpload] = useState<FileUploadProp | null>(null);

    async function handleUpload(files: File[]) {
        setOpenLoad(true);
        try {
            const infoFile = files.map(file => ({
                file,
                uploaded: false,
                preview: URL.createObjectURL(file),
                url: null
            }));

            const data = new FormData();
            data.append('file', infoFile[0].file);
            if(aditional && aditional.length > 0){
                aditional.forEach((element:AditionalData) => {
                    data.append(element.name, element.value);
                });
            }
            const response = await api.post(route, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.success) {
                    setFileUpload(infoFile[0]);
                    AlertInfo({ icon: 'success', title: "Arquivo Salvo!" });
                } else if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message });
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "Falha na conexão" });
        }
        setOpenLoad(false);
    }

    function renderDragMessage(isDragActive: boolean, isDragReject: boolean) {
        if (!isDragActive) {
            if (fileUpload || currentFile) {
                return (
                    <UploadMsg>
                        <img
                            alt='preview'
                            src={fileUpload ? fileUpload.preview : String(currentFile)}
                            style={{ maxWidth: 150, maxHeight: 90 }}
                        />
                    </UploadMsg>
                );
            }

            return (
                <UploadMsg>
                    <p>Clique ou arraste sua logo aqui</p><br />
                    <FaFileImage size={25} color="#c0c0c0" />
                </UploadMsg>
            );
        }

        if (isDragReject) {
            return (
                <UploadMsg color='#e57878'>
                    <p>Arquivo Invalido!</p><br />
                    <FaXmark size={25} color="#c0c0c0" />
                </UploadMsg>
            );
        }

        return (
            <UploadMsg color='#78e5d5'>
                <p>Solte o arquivo!</p><br />
                <FaFileImage size={25} />
            </UploadMsg>
        );
    }

    return (
        <>
            <SimpleBackdrop open={openLoad} />
            <Dropzone
                accept={{ 'image/png': accept }}
                onDropAccepted={handleUpload}
            >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <div
                        style={{
                            border: '1px dashed #ddd',
                            borderRadius: 4,
                            cursor: 'pointer',
                            marginTop: 15
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {renderDragMessage(isDragActive, isDragReject)}
                    </div>
                )}
            </Dropzone>
            {currentFile && handleDelete && (
                <Button
                    fullWidth
                    color='error'
                    onClick={handleDelete}
                >
                    <FaTrash /> &nbsp; Remover Arquivo
                </Button>
            )}
        </>
    );
}