export const columnsCliente = [
    "{nome_cliente}",
    "{rg_cliente}",
    "{cpf_cliente}",
    "{data_nascimento_cliente}",
    "{idade_cliente}",
    "{idade_cliente_extenso}",
    "{estado_civil_cliente}",
    "{profissao}",
    "{telefone_cliente}",
    "{whatsapp_cliente}",
    "{genero}",
    "{endereco_cliente}",
    "{numero_cliente}",
    "{bairro_cliente}",
    "{complemento_cliente}",
    "{uf_cliente}",
    "{cidade_cliente}",
    "{cep_cliente}",
    "{ponto_referencia_cliente}",
    "{nacionalidade_cliente}",
    "{uf_nascimento_cliente}",
    "{cidade_nascimento_cliente}",
    "{mae_cliente}",
    "{pai_cliente}",
];

export const columnsEscritorio = [
    "{nome_escritorio}",
    "{razao_social_escritorio}",
    "{cnpj_escritorio}",
    "{endereco_escritorio}",
    "{numero_escritorio}",
    "{bairro_escritorio}",
    "{complemento_escritorio}",
    "{uf_escritorio}",
    "{cidade_escritorio}",
    "{cep_escritorio}",
    "{telefone_escritorio}",
    "{email_escritorio}",
    "{image}",
    "{identificar}"
];

export const columnsProcess = [
    "{adicional_advogados}",
    "{servico_advogado}",
    "{causa_processual}",
    "{status_processo}",
    "{codigo_status_processo}",
    "{protocolo_processo}",
    "{data_requerimento}",
    "{numero_beneficio_inss}",
    "{uf_acao_judicial}",
    "{cidade_acao_judicial}",
    "{motivo_indeferimento_inss}",
    "{salario_referencia}",
    "{salario_retroativo}",
    "{total_causa}",
    "{valor_causa}",
    "{data_nascimento_crianca}",
    "{local_nascimento_crianca}",
    "{salario_nascimento_crianca}",
    "{salario_referencia_x12}",
    "{salario_retroativo_extenso}",
    "{salario_referencia_extenso}",
    "{salario_referencia_x12_extenso}",
    "{valor_causa_extenso}",
    "{total_causa_extenso}",
    "{data_hoje}",
    "{adicional_advogados}",
    "{nome_crianca}",
    "{uf_nascimento_crianca}",
    "{cidade_nascimento_crianca}",
];

export const columnsAdv = [
    "{nome_usuario}",
    "{sexo_usuario}",
    "{cpf_usuario}",
    "{telefone_usuario}",
    "{email_usuario}",
    "{cod_oab}",
    "{sessao_oab_usuairo}",
    "{nome_abreviado_usuario}",
    "{profissao_usuario}",
    "{estado_civil_usuario}",
    "{nacionalidade_usuario}",
    "{rg_usuario}",
    "{emissor_rg_usuario}",
];