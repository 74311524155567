import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Switch, Typography } from "@mui/material";
import Templete from "../../template/Template";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import InputLabel from "../../components/Inputs/InputLabel";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { permissions } from "../../data/permissoesUsuario";
import { UsuarioType } from "../../types/UsuarioType";
import { api, apiUrl } from "../../services/api";
import SelectSimple from "../../components/Selects/SelectSimple";
import { estadoCivilData, nacionalidadeData } from "../../data/dataCliente";
import { isPermite } from "../../utilities/isPermite";
import DropZoneFile from "../../components/DropZone/DropZoneFile";

export default function CadastroUsuario() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [openLoad, setOpenLoad] = useState(false);
    const [id_usuario, setIdUsuario] = useState<number | null>(null);
    const [nome_usuario, setNomeUsuario] = useState("");
    const [nome_abreviado_usuario, setNomeAbreviadoUsuario] = useState("");
    const [telefone_usuario, setTelefoneUsuario] = useState("");
    const [cpf_usuario, setCpfUsuario] = useState("");
    const [email_usuario, setEmailUsuario] = useState("");
    const [senha_usuario, setSenhaUsuario] = useState("");
    const [profissao_usuario, setProfissaoUsuario] = useState("");
    const [nacionalidade_usuario, setNacionalidadeUsuario] = useState<string | number>("");
    const [estado_civil_usuario, setEstadoCivilUsuario] = useState<string | number>("");
    const [rg_usuario, setRgUsuario] = useState("");
    const [emissor_rg_usuario, setEmissorRgUsuario] = useState("");
    const [is_advogado, setIsAdvogado] = useState(false);
    const [cod_oab, setCodOAB] = useState("");
    const [sessao_oab_usuario, setSessaoOabUsuario] = useState("");
    const [logo_user, setLogoUser] = useState<string | null>(null);
    const [permissoes, setPermissoes] = useState(permissions);
    const [is_logo_proprio, setIsLogoProprio] = useState(false);

    async function handleSave() {
        setOpenLoad(true);
        try {
            const permissoes_usuario = permissoes.map(per => {
                const checkList = per.options.filter(p => p.check).map(p => p.value);
                return checkList.toString();
            });
            const data = {
                id_usuario,
                nome_usuario: nome_usuario.toUpperCase(),
                nome_abreviado_usuario: nome_abreviado_usuario.toUpperCase(),
                telefone_usuario, cod_oab, nacionalidade_usuario, sessao_oab_usuario, 
                estado_civil_usuario, rg_usuario, emissor_rg_usuario,
                cpf_usuario, email_usuario, senha_usuario, profissao_usuario,
                permissoes_usuario: permissoes_usuario.filter(i => i !== "").toString(),
                is_advogado: Number(is_advogado), is_logo_proprio: Number(is_logo_proprio),
            };
            const channel = id_usuario ? `/updateusuario` : `/createusuario`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    console.log(response);
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else if (response.data.success) {
                    AlertInfo({ icon: 'success', title: 'DADOS GRAVADOS' }).then(() => {
                        navigate('/usuarios');
                    });
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na conexão' });
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm({ title: "Deletar usuario do sistema?" }).then(async res => {
            if (res.isConfirmed) {
                setOpenLoad(true);
                try {
                    const data = { id_usuario, nome_usuario, email_usuario, usuario_ativo: 0 };
                    const response = await api.post(`/updateusuario`, data);
                    if (response && response.status === 200 && response.data) {
                        if (response.data.error) {
                            AlertInfo({ icon: 'error', title: response.data.message });
                        } else if (response.data.success) {
                            AlertInfo({ icon: 'success', title: 'Usuario removido' }).then(() => {
                                navigate("/usuarios");
                            });
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo({ icon: 'error', title: 'Falha na conexão' });
                }
                setOpenLoad(false);
            }
        });
    }

    function handleCheck(idContainer: string, idValue: number) {
        const up = permissoes.map(per => {
            if (per.id === idContainer) {
                const upOption = per.options.map(p => {
                    if (p.value === idValue) return { ...p, check: !p.check };
                    return p;
                });
                return { ...per, options: upOption };
            }

            return per;
        });

        setPermissoes(up);
    }

    function setDataResponse(dataUsuario: UsuarioType) {
        setIdUsuario(dataUsuario.id_usuario);
        setNomeUsuario(dataUsuario.nome_usuario);
        setNomeAbreviadoUsuario(dataUsuario.nome_abreviado_usuario);
        setCpfUsuario(dataUsuario.cpf_usuario);
        setTelefoneUsuario(dataUsuario.telefone_usuario);
        setEmailUsuario(dataUsuario.email_usuario);
        setProfissaoUsuario(dataUsuario.profissao_usuario);
        setIsAdvogado(Boolean(Number(dataUsuario.is_advogado)));
        setCodOAB(dataUsuario.cod_oab);
        setNacionalidadeUsuario(dataUsuario.nacionalidade_usuario);
        setEstadoCivilUsuario(dataUsuario.estado_civil_usuario);
        setRgUsuario(dataUsuario.rg_usuario);
        setEmissorRgUsuario(dataUsuario.emissor_rg_usuario);
        setSessaoOabUsuario(dataUsuario.sessao_oab_usuario);
        setIsLogoProprio(Boolean(dataUsuario.is_logo_proprio));
        if (dataUsuario.logo_user) setLogoUser(`${apiUrl}/imguploaded/${dataUsuario.logo_user}`);

        const permiteUsuario = dataUsuario.permissoes_usuario.split(',');
        const upPermission = permissoes.map(per => {
            const up = per.options.map(p => {
                if (permiteUsuario.includes(String(p.value))) return { ...p, check: true };
                return p;
            });
            return { ...per, options: up };
        });
        setPermissoes(upPermission);
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_usuario: id };
                const response = await api.post(`/usuario`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                    else setDataResponse(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: "error", title: 'NÃO POSSIVEL BUSCAR DADOS' });
            }
        }
        setOpenLoad(false);
    }

    function deleteLogoUser() {
        AlertConfirm({ title: "Remover Logo do sistema?" }).then(async res => {
            if (res.isConfirmed) {
                try {
                    const filename = String(logo_user).replace(`${apiUrl}/imguploaded/`, "");
                    const response = await api.delete(`/removelogoescritorio/${filename}`);
                    if (response && response.status === 200 && response.data) {
                        if (response.data.success) {
                            AlertInfo({ icon: 'success', title: "Arquivo Removido" });
                            setLogoUser(null);
                        } else if (response.data.error) {
                            AlertInfo({ icon: 'error', title: response.data.message });
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo({ icon: 'error', title: "Falha na Conexão" });
                }
            }
        });
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Templete menuActive="usuarios">
            <Grid container spacing={1}>
                {id_usuario && isPermite("2") && (
                    <>
                        <Grid item xs={false} sm={10}></Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={handleDelete}
                            >
                                Deletar
                            </Button>
                        </Grid>
                    </>
                )}

                <Grid item xs={12} sm={6}>
                    <Paper elevation={4} sx={{ padding: 3 }}>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom align="center" m={0}>
                                    Dados do usuario
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel
                                    label="Nome usuario"
                                    autoFocus={id_usuario ? false : true}
                                    next="nome_abreviado_usuario"
                                    value={nome_usuario}
                                    setState={setNomeUsuario}
                                    isUpper={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    id="nome_abreviado_usuario"
                                    label="Abreviação"
                                    next="profissao_usuario"
                                    value={nome_abreviado_usuario}
                                    setState={setNomeAbreviadoUsuario}
                                    isUpper={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    id="profissao_usuario"
                                    label="Profissão"
                                    next="nacionalidade_usuario"
                                    value={profissao_usuario}
                                    setState={setProfissaoUsuario}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectSimple
                                    id="nacionalidade_usuario"
                                    label="Nacionalidade"
                                    value={nacionalidade_usuario}
                                    setState={setNacionalidadeUsuario}
                                    options={nacionalidadeData}
                                    isNone
                                    next="estado_civil_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectSimple
                                    id="estado_civil_usuario"
                                    label="Estado Civil"
                                    value={estado_civil_usuario}
                                    setState={setEstadoCivilUsuario}
                                    options={estadoCivilData}
                                    isNone
                                    next="rg_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="RG"
                                    id="rg_usuario"
                                    value={rg_usuario}
                                    setState={setRgUsuario}
                                    next="emissor_rg_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Orgão Emissor"
                                    id="emissor_rg_usuario"
                                    value={emissor_rg_usuario}
                                    setState={setEmissorRgUsuario}
                                    next="telefone_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Telefone"
                                    id="telefone_usuario"
                                    value={telefone_usuario}
                                    setState={setTelefoneUsuario}
                                    next="cpf_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="CPF"
                                    id="cpf_usuario"
                                    value={cpf_usuario}
                                    setState={setCpfUsuario}
                                    next="email_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Email"
                                    id="email_usuario"
                                    value={email_usuario}
                                    setState={setEmailUsuario}
                                    next="senha_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Senha"
                                    type="password"
                                    id="senha_usuario"
                                    value={senha_usuario}
                                    setState={setSenhaUsuario}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={is_advogado}
                                            onChange={() => setIsAdvogado(!is_advogado)}
                                        />
                                    }
                                    label="ADVOGADO"
                                />
                            </Grid>
                            {is_advogado && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel
                                            label="OAB"
                                            id="cod_oab"
                                            value={cod_oab}
                                            setState={setCodOAB}
                                            next="sessao_oab_usuario"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel
                                            label="Seção OAB"
                                            id="sessao_oab_usuario"
                                            value={sessao_oab_usuario}
                                            setState={setSessaoOabUsuario}
                                        />
                                    </Grid>
                                    <Grid item xs={false} sm={12} />
                                    {id_usuario && (
                                        <>
                                            <Grid item xs={12} sm={4}>
                                                <DropZoneFile
                                                    route="/saveLogoUsuario"
                                                    accept={['.png', '.jpg']}
                                                    currentFile={logo_user}
                                                    handleDelete={deleteLogoUser}
                                                    aditional={[{ name: 'id_usuario', value: id_usuario }]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={is_logo_proprio}
                                                            onChange={() => setIsLogoProprio(!is_logo_proprio)}
                                                        />
                                                    }
                                                    label="Usar nome e logo nas petições"
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Paper elevation={4} sx={{ padding: 3 }}>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom align="center" m={0}>
                                    Permissoes usuario
                                </Typography>
                            </Grid>

                            {permissoes.map(per => (
                                <Grid item xs={12} sm={6} key={per.id}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormLabel component="legend">{per.label}</FormLabel>
                                        <FormGroup >
                                            {per.options.map(p => (
                                                <FormControlLabel
                                                    key={p.value}
                                                    control={
                                                        <Checkbox
                                                            value={p.value}
                                                            checked={p.check}
                                                            onChange={() => handleCheck(per.id, p.value)}
                                                        />
                                                    }
                                                    label={p.label}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            ))}

                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={false} sm={5}></Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={handleSave}
                    >
                        Salvar
                    </Button>
                </Grid>
                <Grid item xs={false} sm={5}></Grid>

                <SimpleBackdrop open={openLoad} />
            </Grid>
        </Templete>
    );
}