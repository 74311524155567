import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { AlertInfo } from '../Alerts/Alert';
import { api } from '../../services/api';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

type ButtonUploadProps = {
    label: string
    route: string
    validFile?: string
    exeSuccess?: () => void
};

export default function ButtonUpload({label, route, validFile, exeSuccess}:ButtonUploadProps) {
    async function uploadFile(file: FileList | null) {
        if(file){
            console.log(file[0]);
            if(validFile && !String(file[0].name).includes(validFile)) return AlertInfo({icon: 'error', title:'Arquivo Invalido!'});
            const data = new FormData();
            data.append('file', file[0]);
            try {
                const response = await api.post(route, data);
                if(response && response.status && response.status){
                    AlertInfo({icon: 'success', title:'Arquivo Salvo'});
                    if(exeSuccess) exeSuccess();
                }else{
                    AlertInfo({icon: 'error', title:'Falha na operação'});
                }
            } catch (error) {
                console.log(error);
                AlertInfo({icon: 'error', title:'Falha na conexão'});
            }
        }
    }

    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            fullWidth
        >
            {label}
            <VisuallyHiddenInput
                type="file"
                id="inputFileHidden"
                onChange={(event) => uploadFile(event.target.files)}
            />
        </Button>
    );
}
