import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DataObjectIcon from '@mui/icons-material/DataObject';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { formatDate } from "../../utilities/date";
import IconMenu from "../../components/Menu/IconMenu";
import { Button, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ButtonUpload from "../../components/ButtonCard/ButtonUpload";
import Modal from "../../components/Modal/Modal";
import { columnsAdv, columnsCliente, columnsEscritorio, columnsProcess } from "../../data/dataInstrucoes";

type DocumentType = {
    name?: string
}

export default function Documentos() {
    const [openLoad, setOpenLoad] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activeDocument, setActiveDocument] = useState<null | DocumentType>(null);
    const [arrCausas, setArrCausas] = useState(["Ação Penal","Aposentadoria Idade Rural","Aposentadoria por Idade Urbana","Aposentadoria por Tempo de Contribuição","Auxílio Doença Rural","Auxílio Doença Urbano","BPC-LOAS Deficiente","BPC-LOAS Idoso","Danos Morais e Materiais","Divórcio Consensual","Divórcio Litigioso","Empréstimo Indevido","Idenização ao Consumidor","Negociação de Busca e Apreenção","Obrigação de Fazer e Indenização","Pensão Alimenticia","Pensão por Morte","Revisão Aposentadoria","Revisão FGTS","Salário-maternidade Rural","Salário-maternidade Urbano","Seguro DPVAT","Trabalhista como Reclamado","Trabalhista como Reclamante"]);
    const [openModalCausas, setOpenModalCausas] = useState(false);
    const [openModalInstru, setOpenModalInstru] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'name',
            numeric: false,
            label: 'ARQUIVO',
        },
        {
            id: 'atime',
            numeric: false,
            label: 'ULTIMA ALTERAÇÃO',
            format: (dt: any) => formatDate(dt),
        },
    ];

    async function loadData() {
        // try {
        //     const response = await api.get(`/optionsprocesso`);
        //     if (response && response.status === 200 && response.data) {
        //         if (!response.data.error) {
        //             if (response.data.causas) setArrCausas(response.data.causas);
        //         }
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    async function loadDocuments() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/listdocuments`);
            if (response && response.status === 200 && response.data) {
                setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na Conexão' });
        }
        setOpenLoad(false);
    }

    function handleClick(doc: any, event: any) {
        setActiveDocument(doc);
        setAnchorEl(event.currentTarget);
    }

    async function downloadDocument() {
        setOpenLoad(true);
        try {
            const name = activeDocument?.name;
            await api.post(`/downloaddocument`, { name }, { responseType: 'blob' }).then(response => {
                if (response.status === 204) {
                    return AlertInfo({ icon: 'error', title: "Documento não localizado" });
                }
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${name}`;
                link.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na conexão' });
        }
        setAnchorEl(null);
        setOpenLoad(false);
    }

    async function deleteDocument() {
        AlertConfirm({ title: 'Deletar Documento?' }).then(async res => {
            if (res.isConfirmed) {
                setOpenLoad(true);
                setAnchorEl(null);
                try {
                    const name = activeDocument?.name;
                    const response = await api.post(`deletedocument`, { name });
                    if (response && response.status === 200 && response.data) {
                        AlertInfo({ icon: 'success', title: 'Documento Deletado' });
                        loadDocuments();
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo({ icon: 'error', title: 'Falha na conexão' });
                }
                setOpenLoad(false);
            }
        });
    }

    const arrMenu = [
        { icon: <DownloadIcon fontSize="small" color="primary" />, title: "Baixar Documento", handle: downloadDocument },
        { icon: <DeleteForeverIcon fontSize="small" color="primary" />, title: "Deletar Documento", handle: deleteDocument }
    ];

    useEffect(() => {
        loadDocuments();
        loadData();
    }, []);

    return (
        <>
            <SimpleBackdrop open={openLoad} />
            <Modal
                open={openModalCausas}
                setOpen={setOpenModalCausas}
                title="DOCUMENTOS"
            >
                <List>
                    <ListItemText>PETIÇÕES</ListItemText>
                    {arrCausas.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {rows.filter((i:DocumentType) => String(i.name).includes(item)).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                            </ListItemIcon>
                            <ListItemText>{item}</ListItemText>
                        </ListItem>
                    ))}
                    <ListItemText>PROCURAÇÕES E CONTRATOS</ListItemText>
                    <ListItemIcon>
                        {rows.filter((i:DocumentType) => String(i.name).includes('procuracao_digital')).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText>procuracao_digital</ListItemText>
                    <ListItemIcon>
                        {rows.filter((i:DocumentType) => String(i.name).includes('procuracao_assinar')).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText>procuracao_assinar</ListItemText>
                    <ListItemIcon>
                        {rows.filter((i:DocumentType) => String(i.name).includes('contrato_inss_assinar')).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText>contrato_inss_assinar</ListItemText>
                    <ListItemIcon>
                        {rows.filter((i:DocumentType) => String(i.name).includes('contrato_inss_digital')).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText>contrato_inss_digital</ListItemText>
                    <ListItemIcon>
                        {rows.filter((i:DocumentType) => String(i.name).includes('termo_responsabilidade')).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText>termo_responsabilidade</ListItemText>
                    <ListItemIcon>
                        {rows.filter((i:DocumentType) => String(i.name).includes('contrato_hipossuficiencia')).length > 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText>contrato_hipossuficiencia</ListItemText>
                </List>
            </Modal>
            <Modal
                open={openModalInstru}
                setOpen={setOpenModalInstru}
                title="INSTRUÇÕES"
            >
                <List>
                    
                    <ListItem>
                        <ListItemIcon>
                            <DataObjectIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>DADOS CLIENTE</ListItemText>
                    </ListItem>
                    {columnsCliente.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText>{item}</ListItemText>
                        </ListItem>
                    ))}
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <DataObjectIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>DADOS ESCRITORIO</ListItemText>
                    </ListItem>
                    {columnsEscritorio.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText>{item}</ListItemText>
                        </ListItem>
                    ))}
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <DataObjectIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>DADOS ADVOGADO</ListItemText>
                    </ListItem>
                    {columnsAdv.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText>{item}</ListItemText>
                        </ListItem>
                    ))}
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                            <DataObjectIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText>DADOS PROCESSO</ListItemText>
                    </ListItem>
                    {columnsProcess.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText>{item}</ListItemText>
                        </ListItem>
                    ))}
                    <Divider />
                </List>
            </Modal>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <h1>DOCUMENTOS</h1>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <ButtonUpload
                        label="UPLOAD"
                        validFile=".docx"
                        route="/adddocument"
                        exeSuccess={loadDocuments}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setOpenModalCausas(true)}
                        >
                        LISTA
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setOpenModalInstru(true)}
                    >
                        INSTRUÇÕES
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TableDataSimple
                        rows={rows}
                        columns={columns}
                        handleClick={handleClick}
                    />
                    <IconMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        arrMenu={arrMenu}
                    />
                </Grid>
            </Grid>
        </>
    );
}