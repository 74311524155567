import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import InputLabel from "../../components/Inputs/InputLabel";
import InputCity from "../../components/Inputs/InputCity";
import SelectSimple from "../../components/Selects/SelectSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { formatDate, validDate } from "../../utilities/date";
import { estadoCivilData, generoData, nacionalidadeData } from "../../data/dataCliente";
import { arrayUf } from "../../data/dataUf";
import { api } from "../../services/api";
import { ClienteType } from "../../types/ClienteType";
import { isPermite } from "../../utilities/isPermite";

type CadastroClienteProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setNovoCliente?: Dispatch<SetStateAction<number>>
    cliente_id?: number | null
    setClienteId?: Dispatch<SetStateAction<number>>
    reset?: () => void
}

export default function CadastroCliente({ open, setOpen, setNovoCliente, cliente_id, setClienteId, reset }: CadastroClienteProps) {
    const [openLoad, setOpenLoad] = useState(false);
    const [stringInfoLogin] = useState(sessionStorage.getItem("infoLogin"));
    const [infoLogin] = useState(stringInfoLogin ? JSON.parse(stringInfoLogin) : null);
    const [id_cliente, setIdCliente] = useState<number | null>(null);
    const [nome_cliente, setNome] = useState("");
    const [rg_cliente, setRg] = useState("");
    const [cpf_cliente, setCpf] = useState("");
    const [data_nascimento_cliente, setDataNascimento] = useState("");
    const [estado_civil_cliente, setEstadoCivil] = useState<string | number>("");
    const [profissao, setProfissao] = useState("");
    const [telefone_cliente, setTelefone] = useState("");
    const [whatsapp_cliente, setWhatsapp] = useState("");
    const [genero, setGenero] = useState<string | number>("");
    const [endereco_cliente, setEndereco] = useState("");
    const [numero_cliente, setNumero] = useState("");
    const [bairro_cliente, setBairro] = useState("");
    const [complemento_cliente, setComplemento] = useState("");
    const [uf_cliente, setUf] = useState<string | number>('PA');
    const [cidade_cliente, setCidade] = useState("");
    const [cep_cliente, setCep] = useState("");
    const [ponto_referencia_cliente, setPonto_referencia] = useState("");
    const [nacionalidade_cliente, setNacionalidade] = useState<string | number>("");
    const [uf_nascimento_cliente, setUf_nascimento] = useState<string | number>("PA");
    const [cidade_nascimento_cliente, setCidadeNascimento] = useState("");
    const [mae_cliente, setMae] = useState("");
    const [pai_cliente, setPai] = useState("");
    const [id_usuario_create_cliente, setIdUsuarioCreateCliente] = useState(infoLogin ? infoLogin.id_usuario : 0);
    const [nome_usuario_create_cliente, setNomeUsuarioCreateCliente] = useState(infoLogin ? infoLogin.nome_usuario : "");
    const [id_usuario_update_cliente, setIdUsuarioUpdateCliente] = useState(infoLogin ? infoLogin.id_usuario : 0);
    const [nome_usuario_update_cliente, setNomeUsuarioUpdateCliente] = useState(infoLogin ? infoLogin.nome_usuario : "");
    const [create_cliente, setCreateCliente] = useState("");
    const [update_cliente, setUpdateCliente] = useState("");

    function resetStates() {
        setIdCliente(null);
        setNome("");
        setRg("");
        setCpf("");
        setDataNascimento("");
        setEstadoCivil("");
        setProfissao("");
        setTelefone("");
        setWhatsapp("");
        setGenero("");
        setEndereco("");
        setNumero("");
        setBairro("");
        setComplemento("");
        setUf('PA');
        setCidade("");
        setCep("");
        setPonto_referencia("");
        setNacionalidade("");
        setUf_nascimento("PA");
        setCidadeNascimento("");
        setMae("");
        setPai("");
        setIdUsuarioCreateCliente(infoLogin ? infoLogin.id_usuario : 0);
        setNomeUsuarioCreateCliente(infoLogin ? infoLogin.nome_usuario : "");
        setIdUsuarioUpdateCliente(infoLogin ? infoLogin.id_usuario : 0);
        setNomeUsuarioUpdateCliente(infoLogin ? infoLogin.nome_usuario : "");
        setCreateCliente("");
        setUpdateCliente("");
        if (setClienteId) setClienteId(0);
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                nome_cliente: nome_cliente.toUpperCase(),
                mae_cliente: mae_cliente.toUpperCase(),
                pai_cliente: pai_cliente.toUpperCase(),
                id_cliente, cpf_cliente, rg_cliente, data_nascimento_cliente: validDate(data_nascimento_cliente),
                estado_civil_cliente, profissao, telefone_cliente, whatsapp_cliente, genero,
                endereco_cliente, bairro_cliente, complemento_cliente, uf_cliente, numero_cliente,
                cidade_cliente, cep_cliente, ponto_referencia_cliente, nacionalidade_cliente,
                uf_nascimento_cliente, cidade_nascimento_cliente,
                update_cliente: formatDate(new Date(), "usdH"), cliente_ativo: 1,
                id_usuario_create_cliente, nome_usuario_create_cliente,
                id_usuario_update_cliente: infoLogin ? infoLogin.id_usuario : 0, 
                nome_usuario_update_cliente: infoLogin ? infoLogin.nome_usuario : "",
            }
            const channel = id_cliente ? `/updatecliente` : `/createcliente`;
            const response = await api.post(channel, data);
            console.log(response);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else if (response.data.success) {
                    AlertInfo({ icon: 'success', title: 'Dados Gravados' });
                    if (setNovoCliente) setNovoCliente(response.data.id_cliente);
                    setOpen(false);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na conexão' });
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        if (id_cliente) {
            AlertConfirm({ title: 'Deletar Cliente?' }).then(async res => {
                if (res.isConfirmed) {
                    setOpenLoad(true);
                    try {
                        const data = {
                            id_cliente, cliente_ativo: 0,
                            update_cliente: formatDate(new Date(), "usd"),
                            id_usuario_update_cliente: infoLogin ? infoLogin.id_usuario : 0, 
                            nome_usuario_update_cliente: infoLogin ? infoLogin.nome_usuario : "",
                        }
                        const response = await api.post(`/updatecliente`, data);
                        if (response && response.status === 200 && response.data) {
                            if (response.data.error) {
                                AlertInfo({ icon: 'error', title: response.data.message });
                            } else if (response.data.success) {
                                AlertInfo({ icon: 'success', title: 'Cliente Deletado' });
                                if (reset) reset();
                                resetStates();
                                setOpen(false);
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        AlertInfo({ icon: 'error', title: 'Falha na conexão' });
                    }
                    setOpenLoad(false);
                }
            });
        }
    }

    function setDataCliente(dataCliente: ClienteType) {
        if (dataCliente && dataCliente.id_cliente) {
            setIdCliente(dataCliente.id_cliente);
            setNome(dataCliente.nome_cliente);
            setRg(dataCliente.rg_cliente);
            setCpf(dataCliente.cpf_cliente);
            setDataNascimento(formatDate(dataCliente.data_nascimento_cliente, "usd"));
            setEstadoCivil(dataCliente.estado_civil_cliente);
            setProfissao(dataCliente.profissao);
            setTelefone(dataCliente.telefone_cliente);
            setWhatsapp(dataCliente.whatsapp_cliente);
            setGenero(dataCliente.genero);
            setEndereco(dataCliente.endereco_cliente);
            setNumero(dataCliente.numero_cliente);
            setBairro(dataCliente.bairro_cliente);
            setComplemento(dataCliente.complemento_cliente);
            setUf(dataCliente.uf_cliente);
            setCidade(dataCliente.cidade_cliente);
            setCep(dataCliente.cep_cliente);
            setPonto_referencia(dataCliente.ponto_referencia_cliente);
            setNacionalidade(dataCliente.nacionalidade_cliente);
            setUf_nascimento(dataCliente.uf_nascimento_cliente);
            setCidadeNascimento(dataCliente.cidade_nascimento_cliente);
            setMae(dataCliente.mae_cliente);
            setPai(dataCliente.pai_cliente);
            setCreateCliente(formatDate(dataCliente.create_cliente, "brH"));
            setUpdateCliente(formatDate(dataCliente.update_cliente, "brH"));
            setIdUsuarioCreateCliente(dataCliente.id_usuario_create_cliente);
            setIdUsuarioUpdateCliente(dataCliente.id_usuario_update_cliente);
            setNomeUsuarioCreateCliente(dataCliente.nome_usuario_create_cliente);
            setNomeUsuarioUpdateCliente(dataCliente.nome_usuario_update_cliente);
        }
    }

    async function isExists() {
        if (!id_cliente && cpf_cliente.length > 0) {
            setOpenLoad(true);
            try {
                const data = { cpf_cliente };
                const response = await api.post(`/cliente`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data[0]) {
                        AlertInfo({ icon: 'info', title: "CPF já cadastrado no sistema" });
                        setDataCliente(response.data[0]);
                    }
                }
            } catch (error) {
                console.log(error);
            }
            setOpenLoad(false);
        }
    }

    async function loadData() {
        if (cliente_id && Number(cliente_id) > 0) {
            setOpenLoad(true);
            try {
                const data = { id_cliente: cliente_id };
                const response = await api.post(`/cliente`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                    else setDataCliente(response.data[0]);
                }
            } catch (error) {
                console.log(error);
            }
            setOpenLoad(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [cliente_id]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Cadastro Cliente"
            maxWidth="md"
            zIndex={2222}
            actionClose={resetStates}
        >
            <Grid container spacing={1}>
                <SimpleBackdrop open={openLoad} />
                <Grid item xs={12}>
                    <InputLabel
                        label="Nome"
                        id="nome_cliente"
                        value={nome_cliente}
                        setState={setNome}
                        next="rg_cliente"
                        isUpper
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        label="Rg"
                        id="rg_cliente"
                        value={rg_cliente}
                        setState={setRg}
                        next="cpf_cliente"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        label="CPF"
                        id="cpf_cliente"
                        value={cpf_cliente}
                        setState={setCpf}
                        next="data_nascimento_cliente"
                        mask="cpf"
                        onBlur={isExists}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        label="Data de nascimento"
                        id="data_nascimento_cliente"
                        type="date"
                        value={data_nascimento_cliente}
                        setState={setDataNascimento}
                        next="estado_civil_cliente"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectSimple
                        label="Estado Civil"
                        id="estado_civil_cliente"
                        value={estado_civil_cliente}
                        setState={setEstadoCivil}
                        options={estadoCivilData}
                        next="profissao"
                        isNone
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        label="Profissão"
                        id="profissao"
                        value={profissao}
                        setState={setProfissao}
                        next="telefone_cliente"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        label="Telefone"
                        mask="telefone"
                        id="telefone_cliente"
                        value={telefone_cliente}
                        setState={setTelefone}
                        placeholder="DDD + Numero"
                        next="whatsapp"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        id="whatsapp"
                        label="Whatsapp"
                        value={whatsapp_cliente}
                        setState={setWhatsapp}
                        placeholder="DDD + Numero"
                        mask="telefone"
                        next="genero"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectSimple
                        label="Genero"
                        id="genero"
                        value={genero}
                        setState={setGenero}
                        options={generoData}
                        next="endereco"
                        isNone
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel
                        id="endereco"
                        label="Endereço"
                        value={endereco_cliente}
                        setState={setEndereco}
                        next="numero"
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputLabel
                        id="numero"
                        label="Numero"
                        value={numero_cliente}
                        setState={setNumero}
                        next="bairro"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        id="bairro"
                        label="Bairro"
                        value={bairro_cliente}
                        setState={setBairro}
                        next="complemento"
                    />
                </Grid>
                <Grid item xs={7}>
                    <InputLabel
                        id="complemento"
                        label="Complemento"
                        value={complemento_cliente}
                        setState={setComplemento}
                        next="cidade"
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SelectSimple
                        label="UF"
                        id="uf"
                        value={uf_cliente}
                        setState={setUf}
                        options={arrayUf}
                        next="cidade"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputCity
                        id="cidade"
                        uf={uf_cliente}
                        value={cidade_cliente}
                        setValue={setCidade}
                        next="cep"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        id="cep"
                        label="Cep"
                        value={cep_cliente}
                        setState={setCep}
                        mask="cep"
                        next="nacionalidade"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectSimple
                        id="nacionalidade"
                        label="Nacionalidade"
                        value={nacionalidade_cliente}
                        setState={setNacionalidade}
                        options={nacionalidadeData}
                        isNone
                        next="referencia"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel
                        id="referencia"
                        label="Ponto de referencia"
                        value={ponto_referencia_cliente}
                        setState={setPonto_referencia}
                        next="cidade_nascimento"
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SelectSimple
                        label="UF Nascimento"
                        id="uf_nascimento"
                        value={uf_nascimento_cliente}
                        setState={setUf_nascimento}
                        options={arrayUf}
                        next="cidade_nascimento"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputCity
                        label="Cidade Nascimento"
                        id="cidade_nascimento"
                        uf={uf_nascimento_cliente}
                        value={cidade_nascimento_cliente}
                        setValue={setCidadeNascimento}
                        next="mae"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel
                        id="mae"
                        label="Nome da mãe"
                        value={mae_cliente}
                        setState={setMae}
                        next="pai"
                        isUpper
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel
                        id="pai"
                        label="Nome do pai"
                        value={pai_cliente}
                        setState={setPai}
                        isUpper
                    />
                </Grid>

                {id_cliente && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="button" gutterBottom sx={{ display: 'block' }}>
                                DATA CADASTRO: {create_cliente} | USUARIO: {nome_usuario_create_cliente}
                            </Typography>
                            <Typography variant="button" gutterBottom sx={{ display: 'block' }}>
                                DATA ATUALIZADO: {create_cliente} | USUARIO: {nome_usuario_create_cliente}
                            </Typography>
                        </Grid>
                        <Grid item xs={false} sm={9}></Grid>
                        <Grid item xs={12} sm={3} container alignItems="end">
                            {isPermite("11") && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={handleDelete}
                                >
                                    DELETAR CLIENTE
                                </Button>
                            )}
                        </Grid>
                    </>
                )}

                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={handleSave}
                    >
                        SALVAR CLIENTE
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Modal>
    );
}