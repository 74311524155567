import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import SelectSimple from "../../components/Selects/SelectSimple";
import InputLabel from "../../components/Inputs/InputLabel";
import InputCity from "../../components/Inputs/InputCity";
import { arrayUf } from "../../data/dataUf";
import { displayMoney, removeMask } from "../../utilities/displayValues";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";

type GetStartPlanProps = {
    id: number
    title: string
    price: number
    text: string
}

export default function CadastroEscritorio() {
    const navigate = useNavigate();
    const [openLoad, setOpenLoad] = useState(false);
    const [nome_escritorio, setNomeEscritorio] = useState("");
    const [razao_social_escritorio, setRazaoSocialEscritorio] = useState("");
    const [cnpj_escritorio, setCnpjEscritorio] = useState("");
    const [endereco_escritorio, setEnderecoEscritorio] = useState("");
    const [numero_escritorio, setNumeroEscritorio] = useState("");
    const [bairro_escritorio, setBairroEscritorio] = useState("");
    const [complemento_escritorio, setComplementoEscritorio] = useState("");
    const [uf_escritorio, setUfEscritorio] = useState<string | number>("PA");
    const [cidade_escritorio, setCidadeEscritorio] = useState("");
    const [cep_escritorio, setCepEscritorio] = useState("");
    const [telefone_escritorio, setTelefoneEscritorio] = useState("");
    const [email_escritorio, setEmailEscritorio] = useState("");

    async function handlePlan(id:number, price:number) {
        setOpenLoad(true);
        try {
            let id_escritorio = 0;
            const dataEscritorio = {
                nome_escritorio, razao_social_escritorio, cnpj_escritorio,
                endereco_escritorio, numero_escritorio, bairro_escritorio, complemento_escritorio,
                uf_escritorio, cidade_escritorio, cep_escritorio,
                telefone_escritorio, email_escritorio
            };
            const responseEsc = await api.post(`/createescritorio`, dataEscritorio);
            if(responseEsc && responseEsc.status === 200 && responseEsc.data){
                id_escritorio = responseEsc.data.id_escritorio;
                const dataCliente = {
                    "name": razao_social_escritorio,
                    "email": email_escritorio,
                    "phone": removeMask(telefone_escritorio),
                    "cpfCnpj": removeMask(cnpj_escritorio)
                };
                const data = { dataCliente, price, id_escritorio, id_plano_pagamento: id, externalReference: id_escritorio };
                const response = await api.post(`/createpix`, data);
                if(response && response.status === 200 && response.data){
                    if(response.data.invoiceUrl){
                        AlertInfo({icon: 'success', title: 'DADOS GRAVADOS'}).then(() => {
                            window.open(response.data.invoiceUrl);
                            navigate('/congratulations');
                        });
                    }
                }
            }


        } catch (error) {
            console.log(error);
            AlertInfo({icon: 'error', title: 'Falha na Conexão'});
        }
        setOpenLoad(false);
    }

    function GetStartPlan({ id, title, price, text }: GetStartPlanProps) {
        return (
            <Card sx={{ minWidth: 275, backgroundColor: '#F1F1F1' }}>
                <CardContent>
                    <Typography variant="h5" component="div" align="center">
                        {title}
                    </Typography>
                    <Typography variant="h3" align="center">
                        R$ {displayMoney(price)}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }} align="center">
                        {text}
                    </Typography>
                </CardContent>
                <CardActions style={{ padding: '12px 52px' }}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={() => handlePlan(id, price)}
                    >
                        COMEÇAR
                    </Button>
                </CardActions>
            </Card>
        );
    }

    return (
        <div style={{ backgroundColor: '#F1F1F1', width: '100%', height: '100vh', padding: '8px 152px' }}>
            <Paper elevation={4} sx={{ padding: 4 }}>
                <SimpleBackdrop open={openLoad} />

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }} >Dados Escritorio</h2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="nome_escritorio"
                            label="Nome Escritorio"
                            value={nome_escritorio}
                            setState={setNomeEscritorio}
                            next="razao_social_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="razao_social_escritorio"
                            label="Razão Social Escritorio"
                            value={razao_social_escritorio}
                            setState={setRazaoSocialEscritorio}
                            next="cnpj_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="cnpj_escritorio"
                            label="CNPJ Escritorio"
                            value={cnpj_escritorio}
                            setState={setCnpjEscritorio}
                            next="endereco_escritorio"
                            mask="cnpj"
                        />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <InputLabel
                            id="endereco_escritorio"
                            label="Endereço Escritorio"
                            value={endereco_escritorio}
                            setState={setEnderecoEscritorio}
                            next="numero_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputLabel
                            id="numero_escritorio"
                            label="Numero Escritorio"
                            value={numero_escritorio}
                            setState={setNumeroEscritorio}
                            next="bairro_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="bairro_escritorio"
                            label="Bairro Escritorio"
                            value={bairro_escritorio}
                            setState={setBairroEscritorio}
                            next="cidade_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <SelectSimple
                            label="UF"
                            id="uf"
                            value={uf_escritorio}
                            setState={setUfEscritorio}
                            options={arrayUf}
                            next="cidade_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputCity
                            id="cidade_escritorio"
                            uf={uf_escritorio}
                            value={cidade_escritorio}
                            setValue={setCidadeEscritorio}
                            next="cep_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="cep_escritorio"
                            label="CEP Escritorio"
                            value={cep_escritorio}
                            setState={setCepEscritorio}
                            next="complemento_escritorio"
                            mask="cep"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <InputLabel
                            id="complemento_escritorio"
                            label="Complemento"
                            value={complemento_escritorio}
                            setState={setComplementoEscritorio}
                            next="telefone_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="telefone_escritorio"
                            label="Telefone Escritorio"
                            value={telefone_escritorio}
                            setState={setTelefoneEscritorio}
                            next="email_escritorio"
                            mask="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="email_escritorio"
                            label="Email Escritorio"
                            value={email_escritorio}
                            setState={setEmailEscritorio}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h3 style={{ textAlign: 'center' }} >Escolha seu plano</h3>
                    </Grid>
                    <Grid item xs={false} sm={4}>
                        <GetStartPlan title="Basic" price={99.9} text="Apenas 1 usuario" id={1} />
                    </Grid>
                    <Grid item xs={false} sm={4}>
                        <GetStartPlan title="Pro" price={119.9} text="Até 3 usuarios" id={2} />
                    </Grid>
                    <Grid item xs={false} sm={4}>
                        <GetStartPlan title="Premium" price={149.9} text="Usuarios ilimitados" id={3} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}