import React from "react";
import { Button, TextField, TextFieldProps } from "@mui/material";
import { cep, cpf, cnpj, telefone } from "./masks";

type InputLabelProps = TextFieldProps & {
    next?: string
    setState?: React.Dispatch<React.SetStateAction<string>>
    mask?: 'cpf' | 'telefone' | 'cep' | 'cnpj',
    isUpper?: Boolean,
    isButton?: Boolean,
    iconButton?: JSX.Element,
    exeButton?: () => void
}

export default function InputLabel({ label, next, setState, mask, isUpper, isButton, iconButton, exeButton, ...rest }: InputLabelProps) {
    const allMasks = { cpf, cnpj, telefone, cep };

    function handleNext(event: any) {
        if (event.key === 'Enter') {
            if (next) {
                let nextInput = document.getElementById(next);
                if (nextInput) nextInput?.focus();
            }
        }
    }

    function changeInput(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (setState) {
            if (mask && allMasks[mask]) {
                const addMask = allMasks[mask];
                setState(addMask(e.target.value));
            } else {
                setState(e.target.value);
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, width: "100%" }}>
            <span style={{ fontSize: 15 }}>{label}</span>
            <div style={{display:'flex', flexDirection:'row'}}>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={changeInput}
                    onKeyDown={handleNext}
                    inputProps={{
                        style: isUpper ? { textTransform: 'uppercase' } : {}
                    }}
                    {...rest}
                />
                {isButton && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={exeButton}
                    >
                        {iconButton}
                    </Button>
                )}
            </div>
        </div>
    );
}